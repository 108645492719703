import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material';
import { subDays } from 'date-fns';
import { useEffect, ReactNode, createContext, useState, Dispatch, SetStateAction } from 'react';
import {
  Filter,
  FilterState,
  AppliedFilter,
  FilterTypes,
  FilterStateValue,
  FilterValue,
  StepperFilter,
  DateFilterValue,
  FilterAutocompleteValue,
} from 'src/@types/filter';
import useLocales from 'src/hooks/useLocales';
import { fDT } from 'src/utils/formatTime';
import useViews from '../hooks/useViews';

// ----------------------------------------------------------------------

type FilterCore = {
  filtersMap: Filter[];
  filtersState: FilterState;
  appliedFilters: AppliedFilter[];
  initialFiltersState: FilterState;
  openFilterDrawer: boolean;
  setOpenFilterDrawer: Dispatch<SetStateAction<boolean>>;
  customDateDialogOpen: string | false;
  setCustomDateDialogOpen: Dispatch<SetStateAction<string | false>>;
  setFiltersState: Dispatch<SetStateAction<FilterState>>;
  setAppliedFilters: Dispatch<SetStateAction<AppliedFilter[]>>;
  clearFilters: () => void;
  removeAppliedFilter: (filterToDelete: AppliedFilter, index: number) => void;
  updateFiltersState: (args: {
    type: FilterTypes;
    key: string;
    value: FilterStateValue;
    label?: string;
    checked?: boolean;
    checkedId?: string | number;
    reason?: AutocompleteChangeReason | 'customApplied' | 'replace';
    details?: AutocompleteChangeDetails<FilterValue>;
  }) => void;
  generateInitials: (
    filtersMap: Filter[],
    generateEmpty?: boolean
  ) => { initialAppliedFilters: AppliedFilter[]; initialFiltersState: FilterState };
};

const initialState: FilterCore = {
  filtersMap: [],
  filtersState: {},
  appliedFilters: [],
  initialFiltersState: {},
  setFiltersState: () => {},
  setAppliedFilters: () => {},
  clearFilters: () => {},
  removeAppliedFilter: () => {},
  updateFiltersState: () => {},
  openFilterDrawer: false,
  setOpenFilterDrawer: () => {},
  customDateDialogOpen: false,
  setCustomDateDialogOpen: () => {},
  generateInitials: () => {
    return { initialAppliedFilters: [], initialFiltersState: {} };
  },
};

const FiltersContext = createContext(initialState);

export type FiltersProviderType = {
  filtersMap: Filter[];
};

interface FiltersProviderProps extends FiltersProviderType {
  children: ReactNode;
}

function FiltersProvider({ filtersMap, children }: FiltersProviderProps) {
  const { currentLang, shortDateFormat } = useLocales();
  const { selectedView, initiateFiltersMapsValue } = useViews();

  const formatDate = (date: string | null, subDay = false) =>
    date
      ? fDT({
          date: subDay ? subDays(new Date(date), 1) : date,
          pattern: shortDateFormat,
          options: { locale: currentLang.dateLocale },
        })
      : '';
  const getDateLabel = (key: string, value: DateFilterValue) =>
    `${key}: ${formatDate(value.from)} - ${formatDate(value.to, true)}`;
  const getStepperLabel = (filter: StepperFilter) => `${filter.title}: ${filter.value}`;

  const generateInitials = (filtersMap: Filter[], generateEmpty = false) => {
    const initialFiltersState: FilterState = {};
    const initialAppliedFilters: AppliedFilter[] = [];
    const initialFiltersMap: Filter[] =
      selectedView &&
      Array.isArray(selectedView.savedFilterSetting.filtersMap) &&
      selectedView.savedFilterSetting.filtersMap.length > 0
        ? initiateFiltersMapsValue(
            selectedView.savedFilterSetting.filtersMap as Filter[],
            selectedView.savedFilterSetting.filtersState
          )
        : filtersMap;

    initialFiltersMap.forEach((filter) => {
      // Default State
      switch (filter.type) {
        case 'switch':
          initialFiltersState[filter.stateKey] = generateEmpty ? null : filter.value || null;
          break;
        case 'checkbox':
          initialFiltersState[filter.stateKey] = generateEmpty ? null : filter.value || null;
          break;
        case 'select':
          initialFiltersState[filter.stateKey] = generateEmpty ? '' : filter.value || '';
          break;
        case 'date-select':
          initialFiltersState[filter.stateKey] = generateEmpty ? '' : filter.value || '';
          break;
        case 'autocomplete':
          const defaultValue = filter.props?.multiple ? [] : null;
          initialFiltersState[filter.stateKey] = generateEmpty
            ? defaultValue
            : filter.value || defaultValue;
          break;
        case 'paginated-autocomplete':
          const paginatedDefaultValue = filter.props?.multiple ? [] : null;
          initialFiltersState[filter.stateKey] = generateEmpty
            ? paginatedDefaultValue
            : filter.value || paginatedDefaultValue;
          break;
        case 'stepper':
          initialFiltersState[filter.stateKey] = generateEmpty ? 0 : filter.value || 0;
          break;
        case 'custom-accordion':
        case 'custom-accordion-date':
          initialFiltersState[filter.stateKey] = generateEmpty ? null : filter.value || null;
          break;
        default:
          initialFiltersState[filter.stateKey] = generateEmpty ? null : filter.value || null;
          break;
      }
      // Default Applied Filters
      if (
        filter.value !== undefined &&
        filter.value !== null &&
        // @ts-ignore
        filter.value !== '' &&
        !generateEmpty
      ) {
        if (
          filter.type === 'autocomplete' ||
          filter.type === 'paginated-autocomplete' ||
          filter.type === 'custom'
        ) {
          if (Array.isArray(filter.value)) {
            filter.value.forEach((item) => {
              initialAppliedFilters.push({
                id: item.id,
                stateKey: filter.stateKey,
                label: item.label,
              });
            });
          } else {
            initialAppliedFilters.push({
              id: filter.value.id,
              stateKey: filter.stateKey,
              label: filter.value.label,
            });
          }
        } else if (filter.type === 'custom-accordion' || filter.type === 'custom-accordion-date') {
          if (Array.isArray(filter.value)) {
            filter.value.forEach((item) => {
              initialAppliedFilters.push({
                id: item.id,
                stateKey: filter.stateKey,
                label: item.label,
              });
            });
          } else {
            initialAppliedFilters.push({
              id: filter.value?.id || '',
              stateKey: filter.stateKey,
              label: filter.value?.label || '',
            });
          }
        } else if (filter.type === 'stepper' || filter.type === 'switch') {
          initialAppliedFilters.push({
            id: filter.stateKey,
            stateKey: filter.stateKey,
            label: filter.type === 'stepper' ? getStepperLabel(filter) : filter.title,
          });
        } else if (filter.type === 'date-select') {
          initialAppliedFilters.push({
            id: filter.stateKey,
            stateKey: filter.stateKey,
            label: getDateLabel(filter.title, filter.value),
          });
        } else if (filter.type === 'select') {
          initialAppliedFilters.push({
            id: filter.stateKey,
            stateKey: filter.stateKey,
            label: filter.value.label,
          });
        }
      }
    });
    return { initialFiltersState, initialAppliedFilters };
  };

  const { initialFiltersState, initialAppliedFilters } = generateInitials(filtersMap);
  const [filtersState, setFiltersState] = useState<FilterState>(initialFiltersState);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>(initialAppliedFilters);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [customDateDialogOpen, setCustomDateDialogOpen] = useState<string | false>(false);

  useEffect(() => {
    let newFiltersState = filtersState;
    let newAppliedState = appliedFilters;
    filtersMap.forEach((filter) => {
      if (filter.value === null || (filter.value as FilterValue[])?.length === 0) {
        // Reset State
        newFiltersState[filter.stateKey] =
          generateInitials(filtersMap).initialFiltersState[filter.stateKey];
        // Remove All Related Applied Filters
        newAppliedState = newAppliedState.filter(({ stateKey }) => filter.stateKey !== stateKey);
      } else {
        // @ts-ignore
        if (filter.value !== undefined && filter.value !== '') {
          // Update State
          newFiltersState[filter.stateKey] =
            filter.type === 'select' ? filter.value.value || '' : filter.value;
          // Update Applied Filters
          if (
            filter.type === 'switch' ||
            filter.type === 'stepper' ||
            filter.type === 'select' ||
            filter.type === 'date-select'
          ) {
            if (filter.type === 'date-select') {
              const index = newAppliedState.findIndex(({ id }) => id === filter.stateKey);
              newAppliedState[index] = {
                id: filter.stateKey,
                stateKey: filter.stateKey,
                label: getDateLabel(filter.title, filter.value),
              };
            } else if (newAppliedState.some(({ id }) => id === filter.stateKey)) {
              const index = newAppliedState.findIndex(({ id }) => id === filter.stateKey);
              newAppliedState[index] = {
                id: filter.stateKey,
                stateKey: filter.stateKey,
                label:
                  filter.type === 'switch'
                    ? filter.title
                    : filter.type === 'stepper'
                    ? getStepperLabel(filter)
                    : filter.value.label,
              };
            } else {
              newAppliedState.push({
                id: filter.stateKey,
                stateKey: filter.stateKey,
                label:
                  filter.type === 'switch'
                    ? filter.title
                    : filter.type === 'stepper'
                    ? getStepperLabel(filter)
                    : filter.value.label,
              });
            }
          } else {
            if (Array.isArray(filter.value)) {
              filter.value.forEach((item) => {
                if (newAppliedState.some(({ id }) => id === item.id)) {
                  const index = newAppliedState.findIndex(({ id }) => id === item.id);
                  newAppliedState[index] = {
                    id: item.id,
                    stateKey: filter.stateKey,
                    label: item.label,
                  };
                } else {
                  newAppliedState.push({
                    id: item.id,
                    stateKey: filter.stateKey,
                    label: item.label,
                  });
                }
              });
            } else {
              const index = newAppliedState.findIndex(
                ({ stateKey }) => stateKey === filter.stateKey
              );
              if (index > -1) {
                newAppliedState[index] = {
                  id: filter.value.id,
                  stateKey: filter.stateKey,
                  label: filter.value.label,
                };
              } else {
                newAppliedState.push({
                  id: filter.value.id,
                  stateKey: filter.stateKey,
                  label: filter.value.label,
                });
              }
            }
          }
        }
      }
    });
    // Delete Extra Applied Filters
    const stateKeys = Object.keys(generateInitials(filtersMap).initialFiltersState);
    const valueIds: Array<string | number> = [];
    stateKeys.forEach((key) => {
      const value = newFiltersState[key];
      if (Array.isArray(value)) {
        value.forEach(({ id }) => valueIds.push(id));
      } else {
        if (value) {
          // valueIds.push(typeof value === 'boolean' || typeof value === 'string' || typeof value === 'number' ? key : value.id);
          valueIds.push(
            typeof value === 'boolean' || typeof value === 'string' || typeof value === 'number'
              ? key
              : ''
          );
        }
      }
    });
    // newAppliedState = newAppliedState.filter((appliedFilter) => (
    //   stateKeys.includes(appliedFilter.stateKey) && valueIds.includes(appliedFilter.id)
    // ));
    // HERE

    setFiltersState(newFiltersState);
    setAppliedFilters(newAppliedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersMap]);

  const clearFilters = () => {
    const { initialFiltersState, initialAppliedFilters } = generateInitials(filtersMap, true);
    setFiltersState(initialFiltersState);
    setAppliedFilters(initialAppliedFilters);
  };

  const removeAppliedFilter = (filterToDelete: AppliedFilter, index: number) => {
    const { initialFiltersState } = generateInitials(filtersMap, true);
    const { id, stateKey } = filterToDelete;
    const stateToChange = filtersState[stateKey];
    setAppliedFilters((prev) => prev.filter((f, i) => i !== index));
    setFiltersState({
      ...filtersState,
      [stateKey]: Array.isArray(stateToChange)
        ? stateToChange.filter((item) => item.id !== id)
        : initialFiltersState[stateKey],
    });
  };

  const updateFiltersState: FilterCore['updateFiltersState'] = ({
    type,
    key,
    value,
    label = '',
    checked,
    checkedId,
    reason,
    details,
  }) => {
    setFiltersState({ ...filtersState, [key]: value });
    setAppliedFilters((prev) => {
      if (type === 'switch') {
        return value
          ? [...prev, { id: key, stateKey: key, label }]
          : prev.filter(({ id }) => id !== key);
      } else if (type === 'checkbox') {
        if (checkedId !== undefined) {
          if (checked) return [...prev, { id: checkedId, stateKey: key, label }];
          return prev.filter(({ id, stateKey }) => !(id === checkedId && stateKey === key));
        }
      } else if ((type === 'autocomplete' || type === 'paginated-autocomplete') && reason) {
        if (reason === 'clear') return prev.filter(({ stateKey }) => stateKey !== key);
        if (reason === 'removeOption' && details)
          return prev.filter(({ id, stateKey }) => !(id === details.option.id && stateKey === key));
        if (reason === 'customApplied' || reason === 'selectOption') {
          value = value as FilterAutocompleteValue;
          prev = prev.filter(({ stateKey }) => stateKey !== key);
          if (value) {
            if (Array.isArray(value)) {
              return [...prev, ...value.map(({ id, label }) => ({ id, stateKey: key, label }))];
            } else {
              return [...prev, { id: value.id, stateKey: key, label: value.label }];
            }
          }
          return prev;
        }
        if (details)
          return [...prev, { id: details.option.id, stateKey: key, label: details.option.label }];
      } else if (type === 'date-select') {
        value = value as DateFilterValue;
        if (!value.selectValue) return prev.filter(({ id }) => id !== key);
        if (prev.some(({ id }) => id === key)) {
          const index = prev.findIndex(({ id }) => id === key);
          prev[index] = { id: key, stateKey: key, label: getDateLabel(label, value) };
          return prev;
        }
        return [...prev, { id: key, stateKey: key, label: getDateLabel(label, value) }];
      } else if (type === 'stepper' || type === 'select') {
        if (!value) return prev.filter(({ id }) => id !== key);
        if (prev.some(({ id }) => id === key)) {
          const index = prev.findIndex(({ id }) => id === key);
          prev[index] = { id: key, stateKey: key, label };
          return prev;
        }
        return [...prev, { id: key, stateKey: key, label }];
      } else if (type === 'custom-accordion' || type === 'custom-accordion-date') {
        switch (reason) {
          case 'clear': {
            const returnArray = prev.filter((item) => item.stateKey !== key);
            return details?.option.id
              ? [...returnArray, { id: details?.option.id, stateKey: key, label }]
              : returnArray;
          }
          case 'replace': {
            const copyOfAppliedFilters = [...prev];
            const existingKeyIndex = prev.findIndex((item) => item.stateKey === key);
            if (existingKeyIndex > -1) {
              copyOfAppliedFilters.splice(existingKeyIndex, 1, {
                id: details?.option.id || key,
                stateKey: key,
                label,
              });
            } else {
              copyOfAppliedFilters.push({ id: details?.option.id || key, stateKey: key, label });
            }
            return copyOfAppliedFilters;
          }
          default: {
            if (details?.option.label) {
              return [
                ...prev,
                { id: details.option.id, stateKey: key, label: details.option.label },
              ];
            }
          }
        }
      }
      return prev;
    });
  };

  return (
    <FiltersContext.Provider
      value={{
        filtersMap,
        filtersState,
        appliedFilters,
        initialFiltersState,
        setFiltersState,
        setAppliedFilters,
        clearFilters,
        removeAppliedFilter,
        updateFiltersState,
        openFilterDrawer,
        setOpenFilterDrawer,
        customDateDialogOpen,
        setCustomDateDialogOpen,
        generateInitials,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export { FiltersProvider, FiltersContext };
